body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

:root {
    --color-sky: rgba(255, 155, 96);
    --color-ground: rgba(36, 60, 60);
    --color-treetop: rgba(96, 160, 145);
    --color-treeroot: rgba(85, 130, 130);

    --color-header: rgba(233, 220, 160);
}